/* Common styles */

.textAntialiased {
    -webkit-font-smoothing: antialiased;
}

.textAlignCenter {
    text-align: center;
}

.mainTextContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.mainText {
    color: rgba(0, 21, 41, 1);
    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
}

.centerAlignedLine {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}


/* Top Menu */

.logoSize {
    width: 180px;
    height: 40px;
}

.menuBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(240, 240, 240, 1);
    height: 50px;
    position: fixed;
    z-index: 1200;
    box-shadow: 0 1px 4px 0 #cccccc;
}

.menuItems {
    height: 100%;
}

.menuActionLink {
    color: rgba(0, 21, 41, 1);
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 20px;
}

span.menuActionLink:hover {
    color: rgba(240, 240, 240, 1);
    background-color: rgba(0, 21, 41, 0.5);
}

.language {
    padding: 10px;
}

.menuActionLinksContainer {
    display: none;
}

.unselectedBlue {
    fill: gray;
    stroke: gray;
}

.unselectedRed {
    fill: darkgray;
    stroke: darkgray;
}


/* Logo with Gradient block */

.mainTextWhite {
    font-size: 24px;
    color: rgba(240, 240, 240, 1);
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
}

.backgroundImageSize {
    -webkit-background-size: 80%;
    -moz-background-size: 80%;
    -o-background-size: 80%;
    background-size: 80%;
}

.backgroundImageContainer {
    height: 100vh;
    background-color: rgba(0, 21, 41, 1);
    background-repeat: no-repeat;
    background-position: 50% 10%;
    background-attachment: scroll;
    background-image: url(./landing_logo.svg);
    text-align: center;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
}

.backgroundlogoWithGradient {
    height: 100vh;
    background: linear-gradient(rgba(0, 21, 41, 0.60), rgba(143, 140, 140, 0.70));
    background-image: -moz-linear-gradient(top, rgba(0, 21, 41, 0.60), rgba(143, 140, 140, 0.70));
    background-image: -webkit-linear-gradient(top, rgba(0, 21, 41, 0.60), rgba(143, 140, 140, 0.70));
    background-image: -o-linear-gradient(top, rgba(0, 21, 41, 0.60), rgba(143, 140, 140, 0.70));
    background-image: -ms-linear-gradient(top, rgba(0, 21, 41, 0.60), rgba(143, 140, 140, 0.70));
    filter: progid: DXImageTransform.Microsoft.gradient(startColorStr='#66001529', endColorstr='#4c8f8c8c');
}

.registerButtonContainer {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.registerButton {
    color: rgba(240, 240, 240, 1);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border-radius: 50px;
    width: 200px;
    padding: 20px 20px;
    background-color: rgba(0, 21, 41, 1);
}

div.registerButton:hover {
    color: rgba(0, 21, 41, 1);
    background-color: rgba(240, 240, 240, 1);
}

.blinkingArrow {
    animation: colorTransform 1s infinite;
}

@keyframes colorTransform {
    from {
        fill: rgba(240, 240, 240, 1);
        ;
    }
    to {
        fill: rgba(0, 21, 41, 1);
    }
}


/* Why CryptoACE block */

.paddingTopBottom100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.paddingBottom50 {
    padding-bottom: 50px;
}

.backgroundMilk {
    background-color: rgba(240, 240, 240, 1);
}

.secondaryText {
    color: rgba(0, 21, 41, 1);
    font-weight: 600;
    height: 50px;
    font-size: 16px;
}

.roundBlocksColumn {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.leftAlignedContainer {
    text-align: left;
    padding-top: 0px;
    padding-left: 20px;
    width: 85%;
}

.roundButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-left: 10px;
    width: 100%;
}

.roundButton {
    color: rgba(240, 240, 240, 1);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
    padding: 10px 10px;
    height: 50px;
    width: 50px;
    background-color: rgba(0, 21, 41, 1);
}


/* Features block */

.backgroundWhite {
    background-color: white;
}

.descriptionText {
    color: rgba(0, 21, 41, 1);
    font-size: 20px;
    line-height: 1.35;
    padding-left: 20px;
    padding-right: 20px;
}

.wrappedLine {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

.centerAlignedColumn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
}

.topicText {
    color: rgba(0, 21, 41, 1);
    font-size: 20px;
    text-align: center;
}

.topicDescriptionText {
    color: rgba(0, 21, 41, 1);
    font-size: 18px;
    font-weight: 300;
    text-align: center;
}

.paddingBottom30 {
    padding-bottom: 30px;
}

.paddingBottom100 {
    padding-bottom: 100px;
}


/* Contacts block */

.backgroundDark {
    background-color: rgba(0, 21, 41, 1);
}

.paddingTopBottom50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.paddingTop100 {
    padding-top: 100px;
}

.topicTextWhite {
    color: rgba(240, 240, 240, 1);
    font-size: 18px;
    font-weight: 600;
}

.roundWhiteContact {
    padding: 5px;
}

.copyrightTextWhite {
    color: rgba(240, 240, 240, 1);
    font-size: 18px;
    font-weight: 300;
}

.storesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}


/* media queries combined: */

@media only screen and (min-width: 414px) {
    /* For iPhone Plus screens: */
    .mainTextWhite {
        font-size: 28px;
    }
    .mainText {
        font-size: 28px;
    }
    .secondaryText {
        font-size: 18px;
    }
    .topicTextWhite {
        font-size: 20px;
    }
    .copyrightTextWhite {
        font-size: 20px;
    }
    .backgroundImageContainer {
        background-position: 50% 15%;
    }
}

@media only screen and (min-width: 768px) {
    /* For iPad/old Laptop screens: */
    .menuActionLinksContainer {
        display: flex;
        justify-content: flex-end;
        width: 300px;
    }
    .mainTextWhite {
        font-size: 36px;
    }
    .backgroundImageContainer {
        background-attachment: fixed;
        background-position: 50% 20%;
    }
    .mainText {
        font-size: 36px;
    }
    .secondaryText {
        font-size: 24px;
    }
    .leftAlignedContainer {
        padding-top: 5px;
        width: 100%;
    }
    .roundButtonContainer {
        padding-left: 20px;
    }
    .centerAlignedColumn {
        width: 50%;
    }
    .descriptionText {
        font-size: 24px;
    }
    .topicText {
        font-size: 24px;
    }
    .topicDescriptionText {
        font-size: 20px;
    }
    .topicTextWhite {
        font-size: 24px;
    }
    .copyrightTextWhite {
        font-size: 24px;
    }
}

@media only screen and (min-width: 900px) {
    /* For middle screens: */
    .mainTextWhite {
        font-size: 40px;
    }
    .mainText {
        font-size: 40px;
    }
}

@media only screen and (min-width: 1024px) {
    /* big screens: */
    .backgroundImageSize {
        -webkit-background-size: 900px;
        -moz-background-size: 900px;
        -o-background-size: 900px;
        background-size: 900px;
    }
    .mainTextWhite {
        font-size: 48px;
    }
    .mainText {
        font-size: 42px;
    }
    .roundButtonContainer {
        padding-left: 20%;
    }
    .centerAlignedColumn {
        width: 33%;
    }
}