/* Cryptocoins  styles import: */

@import "~cryptocoins-icons/webfont/cryptocoins.css";
@import "~cryptocoins-icons/webfont/cryptocoins-colors.css";

/* AntD styles: */

@import "~antd/dist/antd.css";

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #001529;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected:hover {
    background-color: rgba(220, 220, 255, 0.43);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected:hover
 {
    background-color: rgba(220, 220, 255, 0.43);
}

.ant-menu .ant-alert-info {
    background-color: rgba(255, 255, 255, 0.82);
    border: 1px solid rgba(220, 220, 255, 0.13);
    color: rgba(0, 0, 50, 0.73);
}

.ant-menu-item,
.ant-menu-submenu-title {
    padding: 0 15px;
}

.ant-tabs-large .ant-tabs-nav-container {
    font-size: 16px;
}

.ant-tabs-left .ant-tabs-tab {
    display: inline-block;
}

.ant-tabs-left .ant-tabs-tab > div{
    text-align: right;
}

.top10Table .ant-table-small {
    border: 0px;
    border-radius: 0px;
}

.top10Table .ant-table-small .ant-table-thead > tr > th {
    background-color: white;
}

.top10Table .ant-table-small .ant-table-tbody > tr:last-child > td {
    border-bottom: 0px
}

.ant-btn-primary {
    color: #fff;
    background-color: rgba(85, 96, 123, 0.70);
    border-color: rgba(85, 96, 123, 1);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background-color: rgba(85, 96, 123, 0.50);
    border-color: rgba(85, 96, 123, 1);
}

.ant-spin-dot i {
    background-color: rgba(85, 96, 123, 0.70);
}

.ant-spin-nested-loading>div>.ant-spin {
    position: inherit;
}

/* General App styles: */

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mainApp {
    border: 1px solid lightskyblue;
}


/* Main Header styles: */

.marginLeftAuto {
    margin-left: auto;
}

.flexBlockCenterAligned {
    display: flex;
    align-items: center;
}

.flexBlock {
    display: flex;
}

.flexBlockCenterJustified {
    display: flex;
    justify-content: center;
}

.boldText {
    font-weight: bold;
}

.whiteText {
    color: white;
}

.fullVisibleHeight {
    height: 100vh;
}

.fullHeight {
    height: 100%;
}

.fullWidth {
    width: 100%;
}

.lineHeight {
    line-height: 50px;
}

.imageHeight {
    height: 50px;
}

.textAlignCenter {
    text-align: center;
}

.formattedBlock {
    margin-top: 4px;
    margin-bottom: 4px;
}

.midFullPadding {
    padding: 10px;
}

.widthMultiselect {
    width: 300px;
}

.appLogo {
    background-color: #001529;
    padding: 4px;
}

.menuBackgroundColor {
    background: #001529;
}

.bottomShadow {
    background: #fff;
    padding: 0px;
    box-shadow: 0 1px 4px 0 #cccccc;
}


/* General styles: */

.darkBlueText {
    color: darkblue;
}

.mediumBlueText {
    color: mediumblue;
}

.profitText {
    color: #5cb85c;
}

.lossText {
    color: red;
}

.spinnerInfoText {
    color: black;
    font-size: 20px;
}

.selectedColor {
    color: rgba(85, 96, 123, 0.70);
}


/* Portfolio */

.coinCellAdditionalInfo {
    font-size: 12px;
    color: darkgrey;
    padding-left: 25px;
}


/* Main Layout */

.layoutContent {
    margin: 24px 16px 0;
    overflow: initial;
    padding: 24px;
    background: #fff;
    text-align: center;
    min-height: calc(100vh - 280px);
}


/* Register Form */

.requiredMark:after {
    display: inline-block;
    margin-left: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d;
}